import axios from 'axios'
import api from './api.js'

//创建一个axios的对象
const instance = axios.create({
    // baseURL: "https://fangzhishop.zhyell.com/api/", //baseURL会在发送请求时拼接在url参数前面  测试环境地址
    baseURL: "https://fzshopbackend.zhyell.com/api/", //baseURL会在发送请求时拼接在url参数前面  测试环境地址
    timeout: 50000
})

//请求拦截
//所有的网络请求都会先走这个方法，我们可以在他里面为请求添加一些自定义的内容
instance.interceptors.request.use(
    function(config) {
        //token是跟服务器约定好的
        // config.headers.token = "123456";
        return config;
    },
    function(err) {
        return Promise.reject(err);
    }
);

//响应拦截
//所有的网络请求返回数据之后都会先执行此方法
//此处可以根据服务器的返回状态码做相应的数据
instance.interceptors.response.use(
    function(response) {
        console.group('全局响应拦截');
        console.log(response);
        console.groupEnd();
        return response;
    },
    function(err) {
        return Promise.reject(err);
    }
);


export function shop_list(data) {
    return instance.post(api.post.shop_list, data)
}
