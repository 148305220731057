import { render, staticRenderFns } from "./home.vue?vue&type=template&id=1c60a695&scoped=true"
import script from "./home.vue?vue&type=script&setup=true&lang=js"
export * from "./home.vue?vue&type=script&setup=true&lang=js"
import style0 from "./home.vue?vue&type=style&index=0&id=1c60a695&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c60a695",
  null
  
)

export default component.exports