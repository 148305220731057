<template>
  <div class="home">
    <div v-if="loading" class="loading">地图加载中...</div>
    <div class="search-container" v-if="!loading">
      <input class="searchText" type="text" v-model="searchQuery" placeholder="请输入关键字搜索门店" />
      <div class="searchButton" @click="searchShops">
        <svg t="1721872898363" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6098" width="64" height="64">
          <path d="M0 0h1024v1024H0z" fill="#FFFFFF" p-id="6099" data-spm-anchor-id="a313x.search_index.0.i2.117c3a81z5M0NI" class="selected"></path>
          <path d="M429.568 796.928a345.6 345.6 0 1 1 345.6-345.6 346.496 346.496 0 0 1-345.6 345.6z m0-622.976a276.864 276.864 0 1 0 276.864 276.864 277.248 277.248 0 0 0-276.864-276.864z" fill="#0082FD" p-id="6100"></path>
          <path d="M378.752 298.496m-79.744 0a79.744 79.744 0 1 0 159.488 0 79.744 79.744 0 1 0-159.488 0Z" fill="#ABE3FF" p-id="6101"></path>
          <path d="M256.384 404.352m-29.952 0a29.952 29.952 0 1 0 59.904 0 29.952 29.952 0 1 0-59.904 0Z" fill="#ABE3FF" p-id="6102"></path>
          <path d="M645.4784 690.0608l65.792-65.792 229.184 229.1584-65.8048 65.8048z" fill="#0082FD" p-id="6103"></path>
        </svg>
      </div>
    </div>
    <div id="handMap"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import AMapLoader from "@amap/amap-jsapi-loader"
import { shop_list } from '../../utils/request.js'

const shopdata = ref([])
const searchQuery = ref('')
const loading = ref(true)
let mapIns = null
let markers = []
let infoWindow = null
let cluster = null
let loadedShops = 0
const chunkSize = 50 // 每次加载的商店数
const pageSize = 50 // 每页商店数
let currentPage = 1 // 当前页码

const initMap = () => {
  AMapLoader.load({
    key: 'c6c2a4acd835fdcef903c55d5a8f422c',
    version: '2.0',
    plugins: ['AMap.Polygon', 'AMap.ImageLayer', 'AMap.ToolBar', 'AMap.InfoWindow', 'AMap.MarkerClusterer', 'AMap.LabelsLayer']
  }).then((AMap) => {
    mapIns = new AMap.Map('handMap', {
      viewMode: '2D',
      zoom: isMobile() ? 15 : 20,
      center: [115.7886, 38.6854],
      resizeEnable: true,
      mapStyle: 'amap://styles/whitesmoke',
    })

    const imageLayer = new AMap.ImageLayer({
      url: 'https://fangzhioss.zhyell.com/gymapmin.jpg',
      bounds: new AMap.Bounds([115.782685, 38.683246], [115.795583, 38.68736]),
      zooms: [0, 22],
      opacity: 1,
    })

    mapIns.add(imageLayer)
    mapIns.setLimitBounds(new AMap.Bounds([115.782685, 38.683246], [115.795583, 38.68736]))

    const customLayer = new AMap.TileLayer({
      tileUrl: 'https://a.amap.com/jsapi_demos/static/empty.png',
      zIndex: 1,
      zooms: [0, 22],
    })
    mapIns.add(customLayer)
    mapIns.setFitView([imageLayer])
    mapIns.setZoom(18)

    infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(32, 0) })
    loadMarkers()
    mapIns.addControl(new AMap.ToolBar())
    loading.value = false
    mapIns.on('zoomchange', handleZoomChange)
    mapIns.on('moveend', lazyLoadMarkers)
  }).catch(e => {
    console.error(e)
  })
}

const loadMarkers = () => {
  const shopsToLoad = shopdata.value.slice(loadedShops, loadedShops + chunkSize)
  loadedShops += chunkSize
  addMarkers(shopsToLoad)
  if (loadedShops >= shopdata.value.length) {
    // 如果已加载的商店数据大于或等于所有数据，则重置分页参数
    loadedShops = shopdata.value.length;
  }
}

const addMarkers = (shops) => {
  if (!mapIns) return

  // 清除当前地图上的所有标记
  markers.forEach(marker => mapIns.remove(marker))
  markers = []

  requestAnimationFrame(() => {
    shops.forEach(shop => {
      const markerInstance = new AMap.Marker({
        position: [shop.lng, shop.lat],
        title: shop.name,
        icon: new AMap.Icon({
          image: 'https://fangzhioss.zhyell.com/icon_biaoji.png',
          size: new AMap.Size(50, 50),
          imageSize: new AMap.Size(35, 35)
        }),
        collision: true
      })
      markerInstance.on('click', () => {
        const link = isMobile2() ? `https://fzshopphone.zhyell.com/#/pages/storeDetail/storeDetail?id=${shop.id}` : `https://fzshophome.zhyell.com/#/sjDetail?id=${shop.id}`;
        const infoContent = `
          <div>
            <h3>${shop.name}</h3>
            <p>${shop.address}</p>
            <a href="${link}" target="_blank">去官网</a>
          </div>
        `
        infoWindow.setContent(infoContent)
        infoWindow.open(mapIns, markerInstance.getPosition())
      })
      mapIns.add(markerInstance)
      markers.push(markerInstance)
    })

    if (cluster) {
      cluster.setMap(null)
    }
    cluster = new AMap.MarkerClusterer(mapIns, markers, {
      gridSize: 80,
      maxZoom: 15
    })
  })
}

const handleZoomChange = () => {
  const zoom = mapIns.getZoom()
  markers.forEach(marker => {
    if (zoom < 16) {
      marker.hide()
    } else {
      marker.show()
    }
  })

  // 根据 zoom 级别加载不同的数据
  if (zoom >= 18) {
    loadDetailedData()
  } else if (zoom >= 15) {
    loadGeneralData()
  } else {
    loadOverviewData()
  }
}

const loadDetailedData = () => {
  // 加载详细数据的逻辑
}

const loadGeneralData = () => {
  // 加载一般数据的逻辑
}

const loadOverviewData = () => {
  // 加载概览数据的逻辑
}

const searchShops = () => {
  const query = searchQuery.value.toLowerCase()

  if (!query) {
    // 如果搜索框为空，则不进行搜索
    return
  }

  const filteredShops = shopdata.value.filter(shop => shop.name.toLowerCase().includes(query))

  // 清除当前地图上的所有标记
  markers.forEach(marker => mapIns.remove(marker))
  markers = []

  // 添加搜索到的标记
  addMarkers(filteredShops)

  if (filteredShops.length > 0) {
    const firstShop = filteredShops[0]
    const position = new AMap.LngLat(firstShop.lng, firstShop.lat)
    if (!mapIns.getBounds().contains(position)) {
      mapIns.setCenter(position)
    }
    mapIns.setZoom(18)
  }
}

const list = () => {
  return shop_list({ page: currentPage, limit: pageSize }).then(
    (res) => {
      if (res.data.data) {
        const newShops = res.data.data.data
        console.log(newShops,'-----')

        if (newShops.length > 0) {
          shopdata.value = [...shopdata.value, ...newShops]
          currentPage += 1 // 增加页码
          if (!mapIns) {
            initMap()
          } else {
            loadMarkers()
          }
        } else {
          // 没有更多数据时，可以禁用加载更多功能
          console.log('没有更多数据')
        }
      }
    },
    (err) => {
      console.log('获取门店数据失败：', err)
    }
  )
}

const lazyLoadMarkers = () => {
  if (loadedShops < shopdata.value.length) {
    loadMarkers()
  } else {
    // 如果当前数据已全部加载，则请求更多数据
    list()
  }
}

const isMobile = () => {
  const userAgent = navigator.userAgent
  return /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|Mobile/i.test(userAgent)
}

const isMobile2 = () => {
  const userAgent = navigator.userAgent
  const isMobileDevice = /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|Mobile/i.test(userAgent)
  const isLargeScreen = window.innerWidth > 800
  if (/Android/i.test(userAgent) && isLargeScreen) {
    return false
  }
  return isMobileDevice
}

onMounted(() => {
  list()
})
</script>

<style scoped>
.home {
  position: relative;
}

#handMap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.search-container {
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: center;
}

.searchText {
  height: 32px;
  padding: 0 10px;
  width: 100%;
  max-width: 240px;
  line-height: 32px;
  border: 1px solid #d7d8d9;
  border-radius: 2px 0 0 2px;
  border-right: none;
}

.searchButton {
  height: 32px;
  width: 32px;
  line-height: 32px;
  border: 1px solid #d7d8d9;
  border-left: none;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #333;
}
</style>
